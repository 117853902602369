@import '../Common/Styles/constants.scss';

.filters-popup {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  height: 390px;
  letter-spacing: 0;

  &__overrides {
    padding: 0;
    margin: 0;
  }

  &__filter-type-item {
    cursor: pointer;
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $le-padding-sm $le-padding-sm $le-padding-sm $le-padding-lg;

    &:hover {
      background-color: $le-platinum-gray;
    }

    &--active {
      background-color: $le-new-gray;
    }

    &--disabled {
      color: $le-spanish-gray;
      &:hover {
        color: $le-spanish-gray;
        background-color: white;
        cursor: auto;
      }
    }
  }

  &__filter-types-section {
    overflow-y: auto;
    width: 190px;
    margin: 0;
    padding: $le-padding-md 0;
    border-right: $le-gray-border;
  }

  &__searchable-list {
    width: 500px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: $le-padding-md 0;
  }

  &__searchable-list-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $le-padding-md $le-padding-md 0 0;
    border-top: $le-gray-border;
    & .action-link {
      font-size: $le-font-size-lg;
    }
  }

  &__list-option {
    cursor: pointer;
    background-color: $le-white;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 $le-padding-md;
    gap: 16px;

    &--even {
      background-color: $le-new-gray;
    }
  }
}

.filter-option-search.ui.dropdown:not(.button) > .default.text {
  color: $le-spanish-gray;
}

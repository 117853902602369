@import 'shared.scss';
@import '../../Common/Styles/constants.scss';

.dashboard-progress-bars {
	display: flex;
	flex-direction: column;
	height: 100%;

	.dashboard-progress-bars__scrollbars-wrapper {
		// height of Widget - header
		height: calc(#{$dashboard-widget-height} - #{$dashboard-widget-header-height + 14px});
	}

	.dashboard-progress-bars__wrapper {
		margin: 0 $le-margin-lg;
	}

	.dashboard-progress-bar {
		padding-bottom: $le-padding-sm;
	}

	.dashboard-progress-bars__configurable-order {
		display: flex;
		flex-shrink: 1;
		padding: 0 $le-padding-md $le-padding-md $le-padding-md;
	}
}

.dashboard-progress-bars__header {
	flex-shrink: 1;
	margin-bottom: $le-margin-sm;

	.dashboard-progress-bars__header-title {
		margin-bottom: $le-margin-xs;
	}
}

.dashboard-progress-bar__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: $le-margin-base-plus;

	.dashboard-progress-bar__header-item {
		font-size: $le-font-size-md;
	}

	.dashboard-progress-bar__header-item {
		font-size: $le-font-size-md;
	}
}
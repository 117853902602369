@import '../../Common/Styles/constants.scss';

.dashboard-area {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	&__title {
		display: flex;
		flex-direction: column;
		flex-shrink: 1;
		padding-bottom: $le-padding-md;
	}
	&__chart {
		display: flex;
		flex-grow: 1;
	}
}
$header-panel-padding-left: 16px;
$header-panel-font-size: 16px;
$header-panel-font-size-sm: 12px;
$header-panel-font-size-md: 14px;
$header-panel-padding-right: 20px;

.header-panel {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: white;
  border-bottom: 1 solid #e5e5e5;

  &.header-panel--top-border {
    border-top: 1 solid #e5e5e5;
  }

  .header-panel-left {
    padding-left: $header-panel-padding-left;

    &.header-panel-left--large-padding {
      padding-left: 24px;
    }
  }

  .header-panel-center {
    padding-left: $header-panel-padding-left;
  }

  .header-panel-right {
    flex: 1;
    padding-right: $header-panel-padding-right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .header-panel-right__close-button {
      cursor: pointer;
      margin-left: 48px;
    }

    .header-panel-right__close-button--margin {
      @media screen and (max-width: 1290px) {
        margin-right: 10px;
      }
    }
  }
}

.header-panel-title-icon {
  margin-right: 16px;
  color: #333;
  vertical-align: middle;
}

$header-panel-title-line-height: 22px;
.header-panel-title-title {
  font-size: $header-panel-font-size;
  font-weight: 500;
  color: #333;
  line-height: $header-panel-title-line-height;
  text-transform: uppercase;
  vertical-align: middle;
}

$header-panel-title-line-height-md: 19px;
.header-panel-title-title--medium {
  line-height: $header-panel-title-line-height-md;
  font-size: $header-panel-font-size-md;
  vertical-align: baseline;
}

.header-panel-title-title--small {
  font-size: $header-panel-font-size-sm;
  vertical-align: baseline;
}

button {
  color: red;
}

@import '../../Styles/constants.scss';

$chart-text-font-size: 20px;
.chart-centered-text {
	position: absolute;
	font-size: $chart-text-font-size;
	font-weight: $le-font-weight-medium;
	color: $le-color-text-invert;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -120%);
	-moz-transform: translate(-50%, -200%);
	-ms-transform: translate(-50%, -180%);
	-o-transform: translate(-50%, -120%);
}
@import '../../Common/Styles/constants.scss';

.dashboard-circular-progress-bar {
  width: 120px;
  display: flex;
  flex-direction: column;

  &__doughnut {
    flex-shrink: 1;
  }

  &__info-section {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@import '../Styles/constants.scss';
@import '../Styles/functions.scss';

.sorting-switch {
	&:hover {
		color: $le-green;
	}
	
	.sorting-switch__icon {
		&.sorting-switch__icon--active {
			color: $le-green;
			font-weight: $le-font-weight-medium;
		}

		&.sorting-switch__icon--hidden {
			visibility: hidden;
		}

		&.sorting-switch__icon--hovered {
			visibility: visible;
			display: inline-block;
		}
	}

	.sorting-switch__text {
		margin-left: $le-margin-lg;
		
		&.sorting-switch__text--hovered {
			color: $le-green;
		}
		
		&.sorting-switch__text--active {
			color: $le-green;
			font-weight: $le-font-weight-medium;
		}
	}
}
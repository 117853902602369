@import '../../Common/Styles/constants.scss';

.dashboard-chart-configurable-dimension-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: $le-font-size-lg;
	font-weight: $le-font-weight-medium;
	&--additional-padding {
		margin-right: $le-padding-md;
	}
}

@import '../../Styles/constants.scss';
@import '../../Styles/mixins.scss';

.empty-page {
	@include centerer(true, true);
	text-align: center;

	$bottom-margin-to-text: 38px;
	$bottom-margin-to-text-lg: 44px;

	.empty-page__spinner-wrapper {
		margin-bottom: $bottom-margin-to-text;

		.empty-page__spinner {
			& > div {
				margin: 0 auto;
			}
		}
	}

	.empty-page__icon {
		display: block;
		margin-bottom: $bottom-margin-to-text;
		color: $le-platinum-gray;

		&.empty-page__icon--small {
			margin-bottom: $bottom-margin-to-text-lg;
		}
	}

	.empty-page__text {
		font-size: $le-font-size-lg;
		line-height: 19px;
		font-weight: $le-font-weight-light;
	}

	.empty-page__action-link-wrapper {
		display: block;
		margin-top: $le-padding-md;
	}
}

@import '../Styles/constants.scss';

$le-pill-height: 20px;
$le-pill-width: 100px;
$le-pill-border-radius: 20px;

.le-pill-wrapper {
	display: inline-block;
}

.le-pill {
	display: flex;
	align-items: center;
	justify-content: center;
	height: $le-pill-height;
	min-width: $le-pill-width;
	border-radius: $le-pill-border-radius;
	padding: 0 $le-padding-base;
	background-color: $le-azureish-blue;
}

.le-pill__text {
	font-size: $le-font-size;
	font-weight: $le-font-weight-light;
	color: $le-united-nations-blue;
} 
@import '../Styles/mixins.scss';
@import '../Styles/constants.scss';

.badge {
	display: flex;
	align-items: center;
	background-color: $le-mastricht-blue;
	color: $le-white;
	padding: $le-padding-xs $le-padding-sm;
	border-radius: $le-border-radius;
}

@import '../Styles/constants.scss';
@import '../Styles/mixins.scss';

.circular-progress-bar {
	/*
     * This fixes an issue where the circular-progress-bar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     *
     * If you're not using "display: flex", you can remove this style.
     */
	width: 100%;
}

.circular-progress-bar__path {
	stroke-linecap: butt;
	transition: stroke-dashoffset 0.5s ease 0s;
}

.circular-progress-bar__trail {
	stroke: $le-snow-white;
}

$colors:
	'contracted-buy' $le-dark-spring-green,
	'matched-buy' $le-green,
	'contracted-sell' $le-blue,
	'matched-sell' $le-blue-light;

.circular-progress-bar__text {
	fill: $le-color-text-invert;
	font-family: $le-font-family-base;
	font-size: $le-font-size-lg;
	font-weight: $le-font-weight-medium;
	text-anchor: middle;

	@include colors('circular-progress-bar__text', $colors, 'fill');
}

.circular-progress-bar__background {
	fill: $le-platinum-gray;
}

.start-marker-icon-wrapper-first {
  margin-left: -56px !important;
  margin-top: -50px !important;
  z-index: 0 !important;
  opacity: 0.8;
}

.map-marker-icon-wrapper-first {
  margin-left: -15px !important;
  margin-top: -15px !important;
  z-index: 100 !important;
}

.map-marker-icon-wrapper-second {
  margin-left: -10px !important;
  margin-top: -25px !important;
  z-index: 75 !important;
}

.map-marker-icon-wrapper-third {
  margin-left: -20px !important;
  margin-top: 0px !important;
  z-index: 50 !important;
}

.power-lane-icon-wrapper {
  margin-left: -12px !important;
  margin-top: -12px !important;
  width: 32px !important;
  height: 32px !important;
  padding: 6px;
  background-color: #fff;
  border-radius: 50%;
}

.polyline-offset-positive-first {
  transform: translate(-6px, 6px);
}

.polyline-offset-positive-second {
  transform: translate(-12px, 12px);
}

.polyline-offset-negative-first {
  transform: translate(6px, -6px);
}

.polyline-offset-negative-second {
  transform: translate(12px, -12px);
}

// react-pivottable/pivottable.css overrides
.pvtUi {
  width: 100%;
  overflow: auto;
  tr:nth-child(2),
  tr:first-child {
    display: none;
  }
  tr:nth-child(3) {
    td:first-child {
      display: none;
    }
  }
}

.pvtTable {
  width: 100%;
  .pvtVal {
    font-weight: 200;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  tr:nth-child(2),
  tr:first-child {
    display: table-row;
  }
  tr:nth-child(3) {
    td:first-child {
      display: table-row;
    }
  }
}

table.pvtTable thead tr th,
table.pvtTable tbody tr th {
  background-color: #fafafa;
  border-right: 1px solid;
  border-color: #e5e5e5;
  border-left: none;
  border-top: none;
  font-size: 14px;
  font-weight: 400;
}

table.pvtTable tbody tr td {
  border-right: 1px solid;
  border-color: #e5e5e5;
  border-left: none;
  border-top: none;
  font-weight: 400;
  color: black;
}

table.pvtTable {
  font-family: 'Avenir', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  color: black;
  top: 0;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;

  .pvtColLabel {
    white-space: nowrap;
  }
}

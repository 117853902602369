@import '../../Common/Styles/constants.scss';

.chart-header {
	&__title {
		font-size: $le-font-size-lg;
		font-weight: $le-font-weight-5;
	}

	&__subtitle {
		font-size: $le-font-size-md;
		font-weight: $le-font-weight-light;
		color: $le-color-text-invert-lightest;
	}
}

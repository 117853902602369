@import '../../Common/Styles/constants.scss';

$dashboard-widget-height: 316px;
$dashboard-widget-header-height: 60px;

.dashboard-chart-padding {
	padding: 0 $le-padding-md $le-padding-md $le-padding-md;
}

.dashboard-header {
	padding: $le-padding-md $le-padding-md 0 $le-padding-md;
}

.dashboard-chart {
	display: flex;
	flex-grow: 1;
	width: 100%;
}

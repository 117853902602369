@import '../../Styles/constants.scss';

.info-box {
	display: flex;
	align-items: center;
	
	.info-box__icon {
		margin-right: $le-margin-sm;
		color: $le-color-text-info;
	}

	.info-box__span {
		vertical-align: top;
		font-size: $le-font-size-md;
	}
}
@import '../Common/Styles/constants.scss';

.date-picker-popup {
	transform: scale(0.9);
	display: flex;
	flex-direction: row;
	padding: 0;
	margin: 0;
	letter-spacing: 0;

	&__button {
		font-size: $le-font-size-md;
		font-weight: $le-font-weight-5;
		align-items: center;
		display: flex;
	}

	&__preset-section {
		flex-shrink: 1;
		margin: -$le-padding-md;
		margin-right: $le-margin-xs;
		padding: $le-padding-md 0;
		padding-right: 2*$le-padding-md;
		border-right: 1px solid $le-platinum-gray;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	&__picker-section {
		flex-grow: 1;
		padding: 0;
		margin: -$le-padding-md;
		margin-left: $le-margin-md;
	}
}

@import '../../Common/Styles/constants.scss';

.dashboard-kpi {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.dashboard-kpi__value {
  padding: 0 0 $le-padding-lg $le-padding-md;
  font-size: 28px;
  font-weight: $le-font-weight-5;
}

.dashboard-kpi__second-value {
  padding: 0 0 $le-padding-md $le-padding-md;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.54);
}

@mixin centerer($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-moz-transform: scale(1.0001) translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;
		transform: translate(-50%, 0);
		-moz-transform: scale(1.0001) translate(-50%, 0);
	} @else if ($vertical) {
		top: 50%;
		transform: translate(0, -50%);
		-moz-transform: scale(1.0001) translate(0, -50%);
	}
}

@mixin transform ($transform) {
	-webkit-transform: $transform;
	-moz-transform: $transform;
	-ms-transform: $transform;
	-o-transform: $transform;
	transform: $transform;
}

@mixin transition ($transition) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin button-variant(
	$background,
	$color,
	$backgroundHover,
	$colorHover: $color
) {
	background-color: $background;
	color: $color;

	&:hover {
		background-color: $backgroundHover;
		color: $colorHover;
	}
}
@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin placeholder {
	@include optional-at-root('::-webkit-input-placeholder') {
		@content;
	}

	@include optional-at-root(':-moz-placeholder') {
		@content;
	}

	@include optional-at-root('::-moz-placeholder') {
		@content;
	}

	@include optional-at-root(':-ms-input-placeholder') {
		@content;
	}

	@include optional-at-root('::placeholder') {
		@content;
	}
}

@mixin sizes ($className, $sizes, $property) {
	@each $size in $sizes {
		&.#{$className}-#{nth($size, 1)} {
			#{$property}: nth($size, 2);
		}
	}
}

@mixin colors ($className, $colors, $property) {
	@each $color in $colors {
		.#{$className}--#{nth($color, 1)} {
			#{$property}: nth($color, 2);
		}
	}
}

@mixin multiply ($className, $multipliers, $property, $multiplicandValue) {
	@each $multiplier in $multipliers {
		&.#{$className}--#{nth($multiplier, 1)} {
			#{$property}: nth($multiplier, 2) * $multiplicandValue;
		}
	}
}

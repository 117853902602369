@import '../../Styles/constants.scss';

$le-chart-legend-padding: 5px;
$le-chart-legend-square-side: 10px;
.chart-legend-label {
	font-size: $le-font-size;
	color: $le-black-text-color;
}

.chart-legend-square {
	display: inline-block;
	background-color: $le-orange;
	width: $le-chart-legend-square-side;
	height: $le-chart-legend-square-side;
	margin-right: $le-chart-legend-square-side;
	margin-left: $le-chart-legend-square-side;
}

.chart-legend-square--contracted-buy {
	background-color: $le-green;
}

.chart-legend-square--matched-buy {
	background-color: $le-light-green;
}

.chart-legend-square--pending-buy {
	background-color: $le-gray-pending-buy-bg;
}

.chart-legend-square--contracted-sell {
	background-color: $le-purple;
}

.chart-legend-square--matched-sell {
	background-color: $le-light-purple;
}

.chart-legend-square--pending-sell {
	background-color: $le-light-blue;
}
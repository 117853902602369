@import '../../Styles/constants.scss';

$action-link-color: $le-united-nations-blue;
$action-link-color-warning: $le-color-error;
$action-link-color-hover: $le-mastricht-blue;
$action-link-color-disabled: $le-pastel-gray;
$action-link-padding: 20px;

.action-link {
	color: $action-link-color;
	font-size: $le-font-size-md;
	font-weight: $le-font-weight-medium;
	cursor: pointer;

	&:active,
	&:hover,
	&.action-link--active {
		color: $action-link-color-hover;
	}
	
	&.action-link--disabled {
		color: $action-link-color-disabled;
		pointer-events: none;
		cursor: default;
	}

	&.action-link--disabled-and-pointable {
		color: $action-link-color-disabled;
		cursor: default;
	}

	&.action-link--padding {
		padding: 0 $action-link-padding;
	}

	&.action-link--selected {
		color: $le-color-text-highlight;
	}

	&.action-link--inverted {
		color: $le-green;

		&:active,
		&:hover,
		&.action-link--active {
			color: $le-dark-spring-green;
		}
	}
}

.action-link--warning {
	color: $action-link-color-warning;
}

.action-link-with-icon {
	display: inline-flex;
	align-items: center;
	padding: 0;
}

.action-link__icon {
	margin-right: $le-margin-base;
}

.action-link-api {
	color: $le-green;
	cursor: pointer;

	&:active,
	&:hover,
	&.action-link--active {
		color: $le-green-dark;
	}
}
@import './Common/Styles/constants.scss';
@import './Common/Styles/shortcuts.scss';

html {
  font-size: 14px;
}

$header-height: 2 * $le-sidebar-item-height;

.dashboard {
  height: 100%;
  flex: 1;
  background-color: #fafafa;
  letter-spacing: 0;

  & .react-grid-item.react-grid-placeholder {
    background: $le-granite-gray;
  }

  &__content {
    height: calc(100vh - #{$header-height});
  }

  &__filters-pill {
    margin-left: $le-margin-base;
  }

  &__filter-pills-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .dashboard__header-panel-center {
    height: 60px;
  }
}

@import './constants.scss';

//flex
.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.flex-inline {
  display: inline-flex;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.equal-width-column {
  flex-grow: 1;
  flex-basis: 0;
}

// paddings
.padding-md {
  padding: $le-padding-md;
}

.padding-base {
  padding: $le-padding-base;
}

.padding-vertical-base {
  padding-top: $le-padding-base;
  padding-bottom: $le-padding-base;
}

.padding-xl {
  padding: $le-padding-xl;
}

.padding-lg {
  padding: $le-padding-lg;
}

.padding-sm {
  padding: $le-padding-sm;
}

.padding-vertical-xs {
  padding-top: $le-padding-sm * 0.5;
  padding-bottom: $le-padding-sm * 0.5;
}

.padding-bottom-xs {
  padding-bottom: $le-padding-sm * 0.5;
}

.padding-top-sm {
  padding-top: $le-padding-sm;
}

.padding-right-sm {
  padding-right: $le-padding-sm;
}

.padding-right-lg {
  padding-right: $le-padding-lg;
}

.padding-vertical-sm {
  padding-top: $le-padding-sm;
  padding-bottom: $le-padding-sm;
}

.padding-left-sm {
  padding-left: $le-padding-sm;
}

.padding-bottom-sm {
  padding-bottom: $le-padding-sm;
}

.padding-right-md {
  padding-right: $le-padding-md;
}

.padding-left-md {
  padding-left: $le-padding-md;
}

.padding-horizontal-md {
  padding-left: $le-padding-md;
  padding-right: $le-padding-md;
}

.padding-vertical-md {
  padding-top: $le-padding-md;
  padding-bottom: $le-padding-md;
}

.padding-horizontal-lg {
  padding-left: $le-padding-lg;
  padding-right: $le-padding-lg;
}

.padding-bottom-lg {
  padding-bottom: $le-padding-lg;
}

.padding-horizontal-xl {
  padding-left: $le-padding-xl;
  padding-right: $le-padding-xl;
}

.padding-vertical-xl {
  padding-top: $le-padding-xl;
  padding-bottom: $le-padding-xl;
}

.padding-vertical-lg {
  padding-top: $le-padding-lg;
  padding-bottom: $le-padding-lg;
}

.padding-right-xl {
  padding-right: $le-padding-xl;
}

.padding-horizontal-base {
  padding-left: $le-padding-base;
  padding-right: $le-padding-base;
}

.padding-top-md {
  padding-top: $le-padding-md;
}

.padding-top-xl {
  padding-top: $le-padding-xl;
}

.padding-top-base {
  padding-top: $le-padding-base;
}

.padding-bottom-xl {
  padding-bottom: $le-padding-xl !important;
}

.padding-right-xl {
  padding-right: $le-padding-xl !important;
}

.padding-right-2xl {
  padding-right: $le-padding-2xl;
}

.padding-bottom-md {
  padding-bottom: $le-padding-md;
}

.padding-bottom-base {
  padding-bottom: $le-padding-base;
}

.no-padding {
  padding: 0 !important;
}

// margin
.margin-top-2xl {
  margin-top: $le-margin-2xl;
}

.margin-right-2xl {
  margin-right: $le-margin-2xl;
}

.margin-right-base {
  margin-right: $le-margin-base;
}

.margin-top-md {
  margin-top: $le-margin-md;
}

.margin-lg {
  margin: $le-margin-lg;
}

.margin-top-lg {
  margin-top: $le-margin-lg;
}

.margin-top-xs {
  margin-top: $le-margin-xs;
}

.margin-bottom-xs {
  margin-bottom: $le-margin-xs;
}

.margin-bottom-sm {
  margin-bottom: $le-margin-sm;
}

.margin-bottom-base {
  margin-bottom: $le-margin-base;
}

.margin-bottom-md {
  margin-bottom: $le-margin-md;
}

.margin-bottom-lg {
  margin-bottom: $le-margin-lg;
}

.margin-lg {
  margin: $le-margin-lg;
}

.margin-horizontal-lg {
  margin-left: $le-margin-lg;
  margin-right: $le-margin-lg;
}

.margin-top-xl {
  margin-top: $le-margin-xl;
}

.margin-bottom-xl {
  margin-bottom: $le-margin-xl;
}

.margin-right-md {
  margin-right: $le-margin-md;
}

.margin-left-sm {
  margin-left: $le-margin-sm;
}

.margin-left-lg {
  margin-left: $le-margin-lg;
}

.margin-left-14 {
  margin-left: 14px;
}

.margin-right-sm {
  margin-right: $le-margin-sm;
}

.margin-right-lg {
  margin-right: $le-margin-lg;
}

.margin-horizontal-sm {
  margin-left: $le-margin-sm;
  margin-right: $le-margin-sm;
}

.margin-horizontal-md {
  margin-left: $le-margin-md;
  margin-right: $le-margin-md;
}

.margin-vertical-md {
  margin-bottom: $le-margin-md;
  margin-top: $le-margin-md;
}

// forms
.label {
  font-size: $le-font-size-lg;
  line-height: $le-line-height-lg;
  font-weight: $le-font-weight-medium;
}

.label-md {
  font-size: $le-font-size-md;
  line-height: $le-line-height-md;
  font-weight: $le-font-weight-light;
}

// layout
.display-none {
  display: none;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-middle {
  vertical-align: middle;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-33 {
  width: 33%;
}

.width-22 {
  width: 22%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-100 {
  width: 100%;
}

.width-input-field-md {
  width: 150px;
}

.height-100 {
  height: 100%;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-color-emphasized {
  color: $le-jet-black;
}

.text-color-granite-gray {
  color: $le-granite-gray;
}

.text-color-space-cadet {
  color: $le-space-cadet-blue;
}

.text-color-malachite-green {
  color: $le-malachite-green;
}

.text-medium-light {
  font-weight: $le-font-weight-light;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.border-bottom-solid {
  border-bottom: 1px solid $le-platinum-gray;
}

.border-top-solid {
  border-top: 1px solid $le-platinum-gray;
}

.box-shadow {
  box-shadow: $le-box-shadow-2;
}

// typography

.font-size {
  font-size: $le-font-size;
}

.font-size-md {
  font-size: $le-font-size-md;
}

.font-size-lg {
  font-size: $le-font-size-lg;
}

.font-size-xl {
  font-size: $le-font-size-xl;
}

.font-weight-5 {
  font-weight: $le-font-weight-5;
}

.uppercase {
  text-transform: uppercase;
}

// opacity
.opacity05 {
  opacity: 0.5;
}

.z-index-0 {
  z-index: $zIndex0;
}

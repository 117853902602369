//== Colors
//

//white
$le-white: white;
$le-snow-white: #fafafa;

//black
$le-black: black;
$le-jet-black: #333;

//grays
$le-granite-gray: #666;
$le-spanish-gray: #999;
$le-new-gray: #f7f7f8;
$le-pastel-gray: #ccc;
$le-timberwolf-gray: #d8d8d8;
$le-platinum-gray: #e5e5e5;

//greens
$le-magic-mint-green: #b9efcc;
$le-teal-dear-green: #85e5a9;
$le-malachite-green: #1dd264;
$le-dark-spring-green: #148f44;
$le-up-forest-green: #0b4c24;

//blues
$le-space-cadet-blue: #183450;
$le-united-nations-blue: #4a90e2;
$le-blue-jeans: #6bb4ed;
$le-azureish-blue-darker: #a9d9ff;
$le-azureish-blue: #dbecff;
$le-azureish-blue-light: #f1f7ff;
$le-azureish-blue-dark: #2a4660;
$le-azureish-blue-darkest: #5c6373;
$le-mastricht-blue: #051d36;
$le-jordy-blue: #92bcee;

//reds
$le-unbleached-silk-red: #ffcec6;
$le-vivid-tangerine-red: #ff9d8d;
$le-portland-red: #ff5236;
$le-scarlet-red: #e82100;
$le-cardinal-red: #9c1600;
$le-togoev-red: #db4653;

//oranges
$le-fluo-orange: #ffc300;
$le-dandelion: #ffdb66;
$le-light-dandelion: #fff3cc;
$le-light-tangelo-orange: #ffb473;
$le-bisque-orange: #ffe1c7;

//v1 - Still left to clean up
$le-light-green: #98c53e;
$le-gray-pending-buy-bg: #e4e4e3;
$le-gray-pending-buy-text: #777877;
$le-gray-pending-buy-text-light: #a1b0b8;
$le-grey: #354548;
$le-grey-font: #848484;
$le-grey-jupiter: #9aa2b6;
$le-light-blue: #2e8ec1;
$le-purple: #564c8d;
$le-light-purple: #865290;
$le-shadow-color: $le-spanish-gray;
$le-black-text-color: #262626;
$le-transparent-shadow-color: rgba(0, 0, 0, 0.14);
//End of v1 - Still left to clean up

// Main colors
$le-green: $le-malachite-green;
$le-green-light: $le-magic-mint-green;
$le-green-dark: $le-up-forest-green;
$le-red: $le-portland-red;
$le-red-light: $le-vivid-tangerine-red;
$le-red-lightest: $le-unbleached-silk-red;
$le-blue-dark: $le-space-cadet-blue;
$le-blue: $le-united-nations-blue;
$le-blue-light: $le-blue-jeans;
$le-blue-lightest: $le-azureish-blue;
$le-orange: $le-fluo-orange;
$le-selection-color-bg: $le-united-nations-blue;
$le-selection-color-text: $le-white;

// Background colors
$le-background-color: white;
$le-background-color-invert: $le-space-cadet-blue;
$le-background-color-invert-light: $le-azureish-blue-dark;
$le-background-color-invert-lighter: $le-spanish-gray;
$le-background-color-info: $le-azureish-blue;

// Text colors
$le-color-text: white;
$le-color-text-invert: $le-jet-black;
$le-color-text-invert-light: $le-granite-gray;
$le-color-text-invert-lightest: $le-spanish-gray;
$le-color-text-invert-lighter: $le-pastel-gray;
$le-color-text-highlight: $le-blue-dark;
$le-color-text-highlight-light: $le-azureish-blue;
$le-color-text-highlight-dark: $le-azureish-blue-darkest;
$le-color-text-info: $le-united-nations-blue;
$le-color-text-info-dark: $le-space-cadet-blue;
$le-color-text-error: $le-red;

// Success/Warning/Danger colors
$le-color-success: $le-green;
$le-color-warning: $le-orange;
$le-color-error: $le-red;
$le-color-danger: $le-red;
$le-color-success-light: lighten($le-green, 10%);
$le-color-warning-light: $le-light-tangelo-orange;
$le-color-danger-light: lighten($le-red, 10%);
$le-notify-color-success: $le-teal-dear-green;
$le-notify-color-warning: $le-light-tangelo-orange;
$le-notify-color-error: $le-vivid-tangerine-red;

//== Dimensions
//
$le-sidebar-item-height: 60px;
$le-footer-notification-height: 64px;
$le-map-wrapper-height: 303px;
$le-map-wrapper-margin-top: 37px;
$le-map-wrapper-margin-bottom: 48px;
$le-order-confirm-footer-height: 96px;
$le-button-padding: 10px;
$le-input-padding-top: 5px;
$le-input-padding-left: 10px;
$le-icon-margin: 10px;
$le-border-radius: 4px;
$le-dialog-border-radius: 10px;
$le-header-content-padding: 20px;
$le-order-margin: 10px;
$le-container-padding-sm: 10px;
$le-container-padding: 20px;
$le-container-padding-xl: 20px;
$le-ratio-labels-font-size: 10px;
$le-label-value-app-block: 2;
$le-login-input-height: 50px;
$le-dialog-width: 470px;
$le-dialog-width-large: 548px;
$le-field-height: 40px;
$le-field-line-height: 18px;

//== Fonts
//
$le-font-family-base: 'Avenir', 'Open sans', sans-serif;
$le-icon-font-family: 'logistic-exchange-v2';

//== Font sizes
//
$le-font-size-sm: 8px;
$le-font-size: 10px;
$le-font-size-md: 12px;
$le-font-size-lg: 14px;
$le-font-size-xl: 16px;
$le-font-size-2xl: 18px;
$le-font-size-3xl: 24px;
$le-font-size-4xl: 36px;
$le-font-size-5xl: 48px;

//== Font weights
//
$le-font-weight-3: 300;
$le-font-weight-5: 500;

$le-font-weight-light: $le-font-weight-3;
$le-font-weight-medium: $le-font-weight-5;

//== Borders
//
$le-border-width: 1px;
$le-border-width-md: 3px;
$le-border-color: $le-platinum-gray;
$le-gray-border: $le-border-width solid $le-border-color;
$le-white-border: $le-border-width solid $le-white;
$le-blue-border: $le-border-width-md solid $le-space-cadet-blue;
$le-field-border: $le-border-width solid $le-pastel-gray;
$le-field-border-active: $le-border-width solid $le-granite-gray;
$le-dialog-border: $le-border-width solid $le-snow-white;

//== Margins
//
$le-margin-3xl: 64px;
$le-margin-2xl: 48px;
$le-margin-xl: 32px;
$le-margin-lg: 24px;
$le-margin-md: 16px;
$le-margin-base-plus: 12px;
$le-margin-base: 10px;
$le-margin-sm: 8px;
$le-margin-xs: 4px;

//== Paddings
//
$le-padding-4xl: 120px;
$le-padding-3xl: 96px;
$le-padding-2xl: 48px;
$le-padding-xl: 32px;
$le-padding-lg: 24px;
$le-padding-md: 16px;
$le-padding-base: 10px;
$le-padding-sm: 8px;
$le-padding-xs: 1px;
$le-field-padding-top: 12px;
$le-field-padding: $le-field-padding-top 8px;
$le-dialog-padding: $le-padding-lg $le-padding-xl $le-padding-xl $le-padding-xl;
$le-textarea-padding: $le-padding-md $le-padding-md $le-padding-xl $le-padding-md;

//== Shadows
//
$le-box-shadow-2: 0 0 8px rgba(37, 40, 43, 0.12);

//== Animation
//
$anim-dur-base: 0.25s;
$anim-effect: linear;

//== Line height
//
$le-line-height-xs: 12px;
$le-line-height-sm: 16px;
$le-line-height-base: 16px;
$le-line-height-md: 17px;
$le-line-height-lg: 19px;
$le-line-height-xl: 24px;
$le-line-height-2xl: 33px;

// Button / DropdownMenu
//
$le-button-width: 130px;
$le-button-height: 32px;
$le-button--height-lg: 42px;
$le-button-padding: $le-padding-lg;

$le-button-full-background: $le-space-cadet-blue;
$le-button-full-color: $le-white;
$le-button-full-background-hover: $le-mastricht-blue;
$le-button-full-background-disabled: $le-pastel-gray;

$le-button-ghost-background: $le-white;
$le-button-ghost-color: $le-space-cadet-blue;
$le-button-ghost-background-hover: $le-space-cadet-blue;
$le-button-ghost-color-hover: $le-white;
$le-button-ghost-background-disabled: $le-white;
$le-button-ghost-color-disabled: $le-pastel-gray;

$le-button-call-to-action-background: $le-green;
$le-button-call-to-action-color: $le-white;
$le-button-call-to-action-background-hover: $le-dark-spring-green;

$le-button-ghost-call-to-action-background: $le-space-cadet-blue;
$le-button-ghost-call-to-action-color: $le-green;
$le-button-ghost-call-to-action-background-hover: $le-green;
$le-button-ghost-call-to-action-color-hover: $le-white;

// Media queries breakpoints
//
$breakpoint-sm-screen-max-width: 1400px;
$breakpoint-xs-screen-max-width: 1290px;
$breakpoint-sm-screen-max-height: 892px;

// Icon content codes
//
$icon-left-double-2-code: '\75';
$icon-right-double-2-code: '\57';
$icon-arrow-left-code: '\58';
$icon-arrow-right-code: '\59';

// Validation
$le-validation-message-height: 8px + $le-line-height-base;

// z-Index
$zIndexMinus2: -2;
$zIndex0: 0;
$zIndex1: 1;
$zIndex2: 2;
$zIndex9: 9;
$zIndex100: 100;
$zIndex99999: 99999;

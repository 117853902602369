@import '../Styles/constants.scss';

.closable-pill {
	font-family: $le-font-family-base;
	font-weight: $le-font-weight-medium;
	font-size: $le-font-size-md;
	background-color: $le-azureish-blue;
	height: $le-button-height;
	display: flex;
	flex-shrink: 1;
	justify-content: space-between;
	align-items: center;
	padding: 0 $le-padding-sm 0 $le-padding-base;
	border-radius: $le-border-radius;
	border: 1px solid $le-united-nations-blue;

	&__icon {
		color: $le-united-nations-blue;
		align-items: center;
		display: flex;
		padding-left: $le-padding-base;
	}
}

@import '../Styles/constants.scss';
@import '../Styles/mixins.scss';

$sizes: 
	'xxs' 8px, 
	'xs' 12px, 
	'sm' 14px, 
	'base' 16px, 
	'md' 18px, 
	'lg' 24px,
	'xl' 48px,
	'xxl' 68px,
	'xxxl' 76px,
	'xxxxl' 88px;

.le-icon {
	display: inline-block;

	@include sizes('le-icon', $sizes, 'font-size');

	.le-icon__indicator {
		position: absolute;
		top: 10px;
		font-size: 5px;
		color: $le-red;
	}
}

.le-icon--clickable {
	cursor: pointer;
	
	&:hover {
		color: darken($le-grey-font, 10%);
		
		&.le-icon--action {
			color: $le-color-text-info-dark;
		}

		&.le-icon--close {
			color: $le-color-text-invert-lightest;
		}

		&.le-icon--dark {
			color: $le-color-text-invert-lightest;
		}
		
		&.le-icon--black {
			color: $le-granite-gray;
		}

		&.le-icon--inverted {
			color: $le-color-text-invert-light;
		}

		&.le-icon--info {
			color: $le-color-text-info;
		}

		&.le-icon--success {  
			color: $le-color-success;
		}

		&.le-icon--warning {  
			color: $le-color-warning;
		}

		&.le-icon--danger {  
			color: $le-color-danger;
		}
	}
}

.le-icon--upside-down {
	&[class^="icon-"]:before,
	&[class*=" icon-"]:before {
		display: inline-block;
		@include transform(rotate(180deg));
	}
}

.le-icon--middle {
	&[class^="icon-"]:before,
	&[class*=" icon-"]:before {
		vertical-align: bottom;
	}
}

.le-icon--action {
	color: $le-color-text-info;
}

.le-icon--close {
	color: $le-pastel-gray;
}

.le-icon--dark {
	color: $le-color-text-invert-lighter;
}

.le-icon--black {
	color: $le-spanish-gray;
}

.le-icon--inverted {
	color: $le-color-text-highlight;
}

.le-icon--info {
	color: $le-color-text-info;
}

.le-icon--success {
	color: $le-color-success-light; 
}

.le-icon--warning {
	color: $le-color-warning-light;
}

.le-icon--danger {
	color: $le-color-danger-light;
}

.le-icon--deemphasized {
	color: $le-granite-gray;
	opacity: 0.5;
}

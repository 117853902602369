@import '../Styles/constants.scss';
@import '~react-day-picker/lib/style.css';

.date-range-picker {
	$day-picker-nav-button-size: 16px;
	.Selectable {
		line-height: 8px;

		$border-radius-circle: 50%;
		.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
			background-color: $le-azureish-blue-light;
			color: $le-united-nations-blue;

			&:last-child{
				border-top-right-radius: $border-radius-circle;
				border-bottom-right-radius: $border-radius-circle;

				&:hover {
					border-top-right-radius: $border-radius-circle;
					border-bottom-right-radius: $border-radius-circle;
				}
			}
			
			&:first-child {
				border-top-left-radius: $border-radius-circle;
				border-bottom-left-radius: $border-radius-circle;

				&:hover {
					border-top-left-radius: $border-radius-circle;
					border-bottom-left-radius: $border-radius-circle;
				}
			}
			
			&.DayPicker-Day--weekday {
				color: $le-color-text-highlight;
			}

			&:hover {
				border-radius: 0;
			}
		}

		 .DayPicker-Day.DayPicker-Day--disabled.DayPicker-Day--selected.DayPicker-Day--disabled.DayPicker-Day--start {
			background-color: $le-united-nations-blue;
			color: $le-white;
		}
		
		.DayPicker-Day {
			border-radius: 0;
			font-weight: $le-font-weight-medium;
			color: $le-jet-black;

			&.DayPicker-Day--disabled {
				color: $le-color-text-invert-lighter;
			}

			&.DayPicker-Day--weekday {
				color: $le-color-text-highlight;
			}

			&:hover {
				border-radius: $border-radius-circle;
			}
		}
		
		.DayPicker-Day--start {
			border-top-left-radius: $border-radius-circle;
			border-bottom-left-radius: $border-radius-circle;
		}
		.DayPicker-Day--end {
			border-top-right-radius: $border-radius-circle;
			border-bottom-right-radius: $border-radius-circle;
		}

		.DayPicker-Day--start, 
		.DayPicker-Day--end {
			border-radius: $border-radius-circle;
		}

		.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
			color: $le-white;
		}

		.DayPicker-Caption {
			margin-bottom: $le-margin-base;
			text-align: center;
			font-weight: $le-font-weight-medium;
			color: $le-jet-black;

			div {
				font-size: $le-font-size-md;
			}
		}
		
		.DayPicker-Month {
			width: 250px;
			margin-left: 0;
			margin-right: 0;
			border-collapse: separate;
			border-spacing: 0 8px;
		}
		
		.DayPicker-Months {
			.DayPicker-Month:first-child {
				margin-right: $le-margin-2xl;
			}
		}

		.DayPicker-Weekday {
			color: $le-jet-black;
			font-size: $le-font-size-md;
		}
		
		.DayPicker-NavButton--prev {
			margin-right: 500px;
			background-image: url(./le_multiple_date_viewer_arrow_left.svg);
			background-size: $day-picker-nav-button-size;
		}

		.DayPicker-NavButton--next {
			margin-right: -12px;
			background-image: url(./le_multiple_date_viewer_arrow_right.svg);
			background-size: $day-picker-nav-button-size;
		}

		.DayPicker-Day {
			padding: 11px;
			font-size: $le-font-size-md;
		}
	}

	&.date-range-picker--one-month {
		.DayPicker-Months {
			.DayPicker-Month:first-child {
				margin-right: 0;
			}
		}

		.DayPicker-NavButton--interactionDisabled {
			display: block;
		}
		
		.DayPicker-NavButton--prev {
			margin-right: 210px;
		}
	}
	
}

.date-range-picker-text {
	font-size: $le-font-size-md;
	font-weight: $le-font-weight-light;
	color: $le-color-text-invert-lightest;
}

.date-range-picker-text--emphasized {
	color: $le-color-text-invert-light;
}